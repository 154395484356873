import * as React from 'react'
import { graphql, Link } from 'gatsby'
import ImageTile from '../components/tiles/photoTile.js'
import Layout from '../components/layout'



const Food = ({ data }) => {
    return (
        <Layout data={data.wpPage}>
          <main>
            <div className="container mx-auto">
              <div className="w-3/4 mx-auto py-20">
                <div class="masonry sm:masonry-sm md:masonry-md">
                {
                    data.allWpFood.nodes.map((node, i) => (
                        <Link key={i} to={node.slug} >
                          <div class="break-inside py-3">
                              <ImageTile imageNode={node.featuredImage} />
                          </div>
                        </Link>
                    ))
                }
                </div>
              </div>
            </div>
          </main>
        </Layout>
    )
}

export const query = graphql`
query {
  wpPage(slug: {eq: "food"}) {
    title
    content
    isFrontPage
    nodeType
    featuredImage {
      node {
        gatsbyImage(width: 1000)
        localFile {
          childImageSharp{
            gatsbyImageData(width:200)
          }
        }
      }
    }
    seo {
      metaDesc
      opengraphUrl
      opengraphType
      opengraphTitle
      opengraphSiteName
      title
    }
    defaults {
      subtitle
      sectionPack {
        fieldGroupName
        sectionName
        sectionFields {
          content
          fieldGroupName
          image {
            gatsbyImage(width: 1000)
          }
        }
      }
    }
  }
  allWpFood {
    nodes {
      id
      title
      slug
      featuredImage {
        node {
          gatsbyImage(width:1000)
          localFile {
            childImageSharp{
              gatsbyImageData(width:200)
            }
          }
        }
      }
    }
  }
}
`
export default Food