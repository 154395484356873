import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'



const ImageTile = ({ imageNode }) => {
  const image = getImage(imageNode.node.gatsbyImage)
  return (
    <div>
        <GatsbyImage image={image} layout="fullWidth" />
    </div>
  )
}
export default ImageTile